(function ($) {
  Drupal.behaviors.checkoutFooterReturnV1 = {
    attach: function (context) {
      // Defined in Drupal, checkout footer - quick links menu
      var $qlReturnOverlay = $('.js-ql-return-open', context);

      // When Return Policy link is clicked, show overlay.
      $qlReturnOverlay.on('click', function () {
        var renderReturn = site.template.get({
          name: 'checkout_footer_ql_return_v1'
        });

        generic.overlay.launch({
          content: renderReturn,
          height: 460,
          width: 580
        });
      });
    }
  };
})(jQuery);
